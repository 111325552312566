@import "../../../styles/variables";

.Championship {
  width: 100%;
  padding: 32px 24px;
  overflow: auto;
}

.Container {
  margin: auto;
  max-width: 1000px;
  margin-top: 40px;
}

.Welcome {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;  
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  padding-left: 40px;
  margin-bottom: 31px;
  @include breakpoint(sm) {
    padding-left: 17px;
  }
}

.Content {
    text-align: start;
}
