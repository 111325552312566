@import "../../../styles/variables";

.SubscriptionPlan {
    width: 100%;
    padding: 30px;
    min-height: 100vh;
    background: url("../../../assets/images/authBackground2.jpg");
    overflow-y: auto;
}

#PrevButton {
  position: absolute;
  left: 0px;
  top: 20px;
  z-index: 10;
  cursor: pointer;
  @include breakpoint(sm) {
    img {
      width: 20.747px;
      height: 41.493px;
    }
  }
}
#NextButton {
  position: absolute;
  right: 0px;
  top: 20px;
  z-index: 10;
  cursor: pointer;
  @include breakpoint(sm) {
    img {
      width: 20.747px;
      height: 41.493px;
    }
  }
}

.Container {
    margin: 0 auto;
    max-width: 800px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Intro {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

#SwiperWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 36px 16px;
    padding-top: 0px;
    width: 100%;
    height: fit-content;
    margin-top: 29px;
  }
  
  #SwiperItem {
    width: 317px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    padding-bottom: 20px;
  }
  
  .Wrapper {
    margin-top: 29px;
    flex-direction: column;
    transition: 0.3s;
  }

  .ContentItem {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 33px;
  }

  .ButtonsWrapper {
    width: 100%;
  }