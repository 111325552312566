.ForgotPassword {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0px 30px;
}

.Wrapper {
  width: 100%;
  margin-top: 11px;
}
