.Quiz {
  width: 100%;
  height: 100vh;
  padding: 30px 20px;
  background: url("../../assets/images/authBackground2.jpg");
}

.Container {
  margin: auto;
  max-width: 800px;
}

.Top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.Wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}
.Info {
  margin-top: 40px;
  text-align: start;
}
.InfoWrapper {
  border-radius: 10px;
  border: 0.5px solid var(--f, rgba(89, 225, 191, 0.92));
  padding: 0px 20px;
  margin-top: 10px;
  padding-top: 20px;
  padding-bottom: 31px;
  margin-bottom: 82px;
}
.InfoItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    p {
        text-align: start;
    }
}
