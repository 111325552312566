@import "../../../styles/variables";

.Home {
  width: 100%;
  padding: 32px 24px;
  overflow-y: scroll;
}

.Container {
    margin: auto;
    max-width: 1000px;
    margin-top: 40px;
}

.Header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

.Welcome {
  border-radius: 20px;
  border: 0.5px solid var(--gray-4, #bdbdbd);
  height: 192px;
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-bottom: 25px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.WelcomeContent {
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#welcomeAdorn1 {
    position: absolute;
    top: 0;
    left: 0;
}
#welcomeAdorn2 {
    position: absolute;
    bottom: 0;
    right: 0;
}
#welcomeAdorn3 {
    position: absolute;
    left: 51;
    bottom: 0;

}
#welcomeAdorn4 {
    position: absolute;
    bottom: 100;
    left: 254;
}

.ContinueLearning {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 33px;
}

.LessonsWrapper {
    width: 100%;
}
.TopicSkeleton {
    height: 92px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
  }