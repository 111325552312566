@import "../../styles/variables";

.Dashboard {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  section {
    display: flex;
    flex: 10;
    width: 100%;
    overflow-x: hidden;
    background: $white;
    align-items: flex-start;
    justify-content: flex-start;
    background: #F6F6F6;
    overflow-y: auto;
    position: relative;
  }
  aside {
    flex: 2;
  }
}

#decor1 {
  position: absolute;
  left: 0;
  top: 10%;
}
#decor2 {
  position: absolute;
  bottom: 0;
  left: 30%;
}
#decor3 {
  position: absolute;
  top: 0;
  right: 0;
}

.PageWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 5;
  overflow-y: scroll;
  padding-bottom: 50px;
}

#ShapeItemDecoration {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: transparent;
  border: 6px solid $sci;
  z-index: 0;
}

#triangle1 {
  position: absolute;
  z-index: 0;
  top: 12%;
  left: 52%;
  animation: animate 10s linear infinite;
}

#ShapeItemDecoration:nth-child(1) {
  top: 12%;
  left: 42%;
  animation: animate 10s linear infinite;
  border-radius: 50%;
}
#ShapeItemDecoration:nth-child(2) {
  top: 70%;
  left: 50%;
  animation: animate 7s linear infinite;
  border-radius: 50%;
}
#ShapeItemDecoration:nth-child(3) {
  top: 17%;
  left: 6%;
  animation: animate 9s linear infinite;
  border: 6px solid $math;
  border-radius: 50%;
}
#ShapeItemDecoration:nth-child(4) {
  top: 20%;
  left: 60%;
  animation: animate 10s linear infinite;
  border-radius: 50%;
  border: 6px solid $math;
}
#ShapeItemDecoration:nth-child(5) {
  top: 67%;
  left: 10%;
  animation: animate 6s linear infinite;
  border: 6px solid $primary;
}
#ShapeItemDecoration:nth-child(6) {
  top: 80%;
  left: 70%;
  animation: animate 12s linear infinite;
  border: 6px solid $primary;
}
#ShapeItemDecoration:nth-child(7) {
  top: 60%;
  left: 80%;
  animation: animate 15s linear infinite;
}
#ShapeItemDecoration:nth-child(8) {
  top: 32%;
  left: 25%;
  animation: animate 16s linear infinite;
  border: 6px solid $primary;
}
#ShapeItemDecoration:nth-child(9) {
  top: 90%;
  left: 25%;
  animation: animate 9s linear infinite;
  border: 6px solid $math;
}
#ShapeItemDecoration:nth-child(10) {
  top: 20%;
  left: 80%;
  animation: animate 5s linear infinite;
}

@keyframes animate {
  0% {
    transform: scale(0) translateY(0) rotate(0);
    opacity: 1;
  }
  100% {
    transform: scale(1.3) translateY(-90px) rotate(360deg);
    opacity: 0;
  }
}

@media (max-width: 900px) {
  .Dashboard {
    flex-direction: column-reverse;
    aside {
      flex: 0;
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 100;
    }
  }
}
