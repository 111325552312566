@import "../../../styles/variables";

.YourLessonLibrary {
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: column;
    @include breakpoint(sm) {
        height: 170px;
    }
}

.Wrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    div {
        display: flex;
        flex: 1 0 25%;
    }
    @include breakpoint(sm) {
        gap: 9px;
    }
}

.Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}