.FullLeaderboard {
  padding: 30px;
  width: 100%;
}

.Container {
  margin: auto;
  max-width: 1000px;
  position: relative;
  top: -30px;
}

.AvatarWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;
}

.Avatar {
  padding: 5px;
  border-radius: 50%;
  border: 7px solid #59e1bf;
  margin-bottom: 6px;
  img {
    width: 77px;
    height: 77px;
    border-radius: 50%;
  }
}

.Header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Wrapper {
  padding: 22px 0px;
}

.ItemSkeleton {
  height: 78px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 22px;
}
