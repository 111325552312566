.ContinueLearning {
    width: 100%;
    margin-top: 33px;
    padding: 0px 20px;
}

.LessonsWrapper {
    width: 100%;
    margin-top: 30px;
}
.TopicSkeleton {
    height: 92px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
  }