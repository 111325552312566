@import "../../../styles/variables";

.Profile {
  width: 100%;
  padding: 32px 24px;
  overflow: auto;
}


.Container {
    margin: auto;
    max-width: 1000px;
    margin-top: 40px;
    align-items: center;
}

.ProfileWrapper {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.Logout {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px;
    img {
      margin-bottom: 42px;
    }
  }
  
  .LogoutButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 14px;
  }
  
  #TextButton {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $grey4;
  }
