.Topic {
  padding: 30px;
  width: 100%;
  height: fit-content;
  overflow: auto;
}

.Container {
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 40px;
}

.TopicHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.TextWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Wrapper {
    margin-top: 31px;
}   

.TopicSkeleton {
    height: 92px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
  }