@import "../../styles/variables";

.Subject {
  width: 100%;
  height: fit-content;
  padding: 30px;
  overflow: auto;
}

.Container {
  max-width: 1000px;
  margin: 0 auto;
}

.Empty {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.GoBackButton {
  display: flex;
  width: 36px;
  margin-left: 0px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.1);
}

.Header {
  width: 100%;
  padding: 0px 0px;
}

.SubHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  width: 100%;
  height: fit-content;
}
.TitleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.IconWrapper {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

#LessonsCount {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #828282;
}

.TopicsWrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}
.TopicSkeleton {
  height: 92px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}
