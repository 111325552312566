.Lesson {
  width: 100%;
  padding: 0px 0px;
  height: fit-content;
  position: relative;
}
.Header {
  position: absolute;
  width: 100%;
  padding: 30px;
  z-index: 10;
}
.PlayerWrapper {
  height: 500px;
  width: 100%;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.Container {
  margin: auto;
  max-width: 1000px;
  padding: 0px 31px;
  padding-top: 20px;
}
.ContentHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}
.TextWrapper {
  text-align: start;
}

.RestrictAccessModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}

.RestrictAccessModalContentWrapper {
  position: relative;
  top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
