@import "../../styles/variables";

.ChooseProfile {
  width: 100%;
  height: 100vh;
  background: url("../../assets/images/authBackground1.jpg");
  background-color: $primary;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  padding-bottom: env(safe-area-inset-top);
}

.ContentWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  position: relative;
}

.Container {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.Wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-top: 64px;
}

.ProfileItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  h4 {
    margin-top: 10px;
    text-align: center;
  }
  img {
    width: 103px;
    height: 103px;
    border-radius: 20px;
    background: #fff;
    object-fit: cover;
  }
  &:hover {
    h4 {
      text-decoration: underline;
    }
  }

  @include breakpoint(sm) {
    img {
      width: 70px;
      height: 70px;
    }
  }
}
