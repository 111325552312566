.MyLessonsCard {
  max-width: 572px;
  width: 100%;
  height: 278px;
  border-radius: 20px;
  background: #00000080;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
