@import "../../styles/variables";

.EditProfile {
  width: 100%;
  padding: 30px;
}

.Container {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  padding: 0px 0px;
}

.AvatarWrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.EditAvatar {
  position: relative;
}

.Form {
  width: 100%;
  margin-top: 30px;
}

.Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  @include breakpoint(sm) {
    flex-direction: column;
    gap: 0px;
  }
}

.InputWrapper {
  width: 100%;
  margin-bottom: 30px;
  p {
    text-align: left;
  }
}

.DeleteProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  img {
    margin-bottom: 20px;
  }
}

.ChangeExhaustedContentWrapper {
  position: relative;
  top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 14px;
}

#TextButton {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $grey4;
}

.GradeWrapper {
  display: flex;
  flex-direction: column;
}

.GradeItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 35px;
  padding: 30px 40px;
  border-bottom: 0.5px solid $grey4;
}
