.Quest {
  padding: 20px;
  width: 100%;
}

.Container {
  margin: auto;
  max-width: 800px;
  margin-top: 40px;
  height: fit-content;
  height: 85vh;
  overflow: hidden;
}

.RestrictAccessModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}

.RestrictAccessModalContentWrapper {
  position: relative;
  top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
