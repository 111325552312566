@import "../../../../styles/variables";

.InputContainer {
  display: flex;
  flex-direction: column;
  height: fit-content;
  flex: 1;
  width: 100%;
  z-index: 100;

  label {
    display: flex;
    flex: 1;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    color: $black1;
  }
}

.InputWrapper {
  display: flex;
  background-color: #fff;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  height: 70px;
  z-index: 5;
  border-radius: 20px;
  border: 0.5px solid $primary;

  &:focus-within {
    border: 1px solid $primary;
  }

  input {
    display: flex;
    height: 100%;
    padding: 29px 54px;
    outline: none;
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    z-index: 10;
    color: $grey2;
    background: transparent;
    &::placeholder {
      color: $black1;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    height: 100%;
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: 15%;

    @include breakpoint(sm) {
      width: 25%;
    }
  }
}

.Error {
  border: 1px solid $error;

  &:focus-within {
    border: 1px solid $error;
  }
}
