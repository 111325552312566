.AuthContainer {
  width: 100%;
  height: 100vh;
  background: url("../../assets/images/authBackground2.jpg");
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px;
  flex: 1;
}

.Header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 35px;
  position: relative;
  align-items: center;

  padding: 0px 30px;
}
.GoBackButton {
  position: absolute;
  display: flex;
  left: 0;
  width: 36px;
  margin-left: 30px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #fff;

  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.1);
}

.Wrapper {
  width: 45%;
}

@media (max-width: 500px) {
  .Wrapper {
    width: 100%;
  }
}
