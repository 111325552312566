@import "../../styles/variables";

.SubjectPreview {
  width: 100%;
  min-height: 100vh;
  padding: 30px;
}

.Container {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 93vh;
  padding-bottom: 30px;
}

.GradeWrapper {
  display: flex;
  flex-direction: column;
}

.GradeItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 35px;
  padding: 30px 40px;
  border-bottom: 0.5px solid $grey4;
}

.ProfileLimitModal {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  padding: 20px;
}

.SubjectHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
}

.HeaderWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.Content {
  margin-top: 29px;
}

.ContentCard {
  width: 100%;
  height: fit-content;
  border-radius: 10px;
  background: var(--white, #fff);
  box-shadow: 0px 10px 3px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 36px 20px;
  position: relative;
  margin-top: 14px;
  gap: 40px;
}

#cardDecor1 {
    position: absolute;
    top: 0;
    left: 0;
}
#cardDecor2 {
    position: absolute;
    bottom: 0;
    right: 0;
}
#cardDecor3 {
    position: absolute;
    bottom: 0;
    right: 0;
}

.ContentItem {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ContentTextWrapper {
    margin-left: 32px;
}
