@import "../../styles/variables";

.Container {
  width: 100%;
  height: fit-content;
  z-index: 10;
  display: flex;
  flex-direction: column;
  h1,h2,h3,h4,h5,h6 {
    text-align: start;
  }
  li {
    text-align: start;
  }
}

.HeroSection {
  height: 120vh;
  width: 100%;
  background-color: $primary;
  z-index: 10;
  position: relative;
  overflow: hidden;
  padding-top: 140px;
  @include breakpoint(sm) {
    padding-top: 70px;
  }
  @include breakpoint(lg) {
    height: 90vh;
  }
}
#HeroBackground {
  @include breakpoint(sm) {
    display: none;
  }
}
#MobileHeroBackground {
  display: none;
  @include breakpoint(sm) {
    display: block;
  }
}

.HeroContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 730px;
  margin: 0 auto;
  position: relative;
  top: 95px;
  z-index: 10;
  h1 {
    color: #111;
    text-align: center;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    margin-bottom: 24px;
    @include breakpoint(sm) {
      font-size: 30px;
      line-height: 44px;
      margin-bottom: 18px;
    }
  }
  p {
    color: #111;
    text-align: start;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 80%;
    margin-bottom: 32px;
    @include breakpoint(sm) {
      width: 100%;
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
  @include breakpoint(sm) {
    width: 100%;
    padding: 0px 37px;
  }
}

#HeroDecor1 {
  position: absolute;
  top: 280px;
  left: 170px;
  z-index: 3;
  @include breakpoint(lg) {
    display: none;
  }
}
#HeroDecor2 {
  position: absolute;
  top: 240px;
  right: 170px;
  z-index: 3;
  @include breakpoint(lg) {
    width: 69.263px;
    height: 55.622px;
    transform: rotate(15deg);
    top: 500px;
    right: 30px;
  }
}
#HeroImage1 {
  position: absolute;
  z-index: 0;
  left: 60px;
  bottom: 100px;
  @include breakpoint(lg) {
    display: none;
  }
}
#HeroImage2 {
  position: absolute;
  bottom: 50px;
  transform: translate(-50%, 0%);
  left: 40%;
  z-index: 0;

  @include breakpoint(xxxl) {
    bottom: -70px;
  }
  @include breakpoint(sm) {
    img {
      width: 240px;
      height: 240px;
    }
    bottom: 30px;
    left: 25%;
  }
}
#HeroImage3 {
  position: absolute;
  z-index: 0;
  right: 0;
  bottom: 100px;
  @include breakpoint(lg) {
    display: none;
  }
}

.PrivacySection {
  padding: 50px 120px;
  position: relative;
  z-index: 100;
  @include breakpoint(sm) {
    padding: 40px 14px;
  }
}

.ContentWrapper {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.98);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: scroll;
  padding: 50px 57px;
  height: 700px;
  @include breakpoint(sm) {
    padding: 50px 30px;
  }
  div {
    margin-bottom: 20px;
  }
  span {
    font-weight: 500;
  }
  h1 {
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-bottom: 60px;
  }
  h3 {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
  }
  p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 155%;
    text-align: justify;
  }
  a {
    color: $primary;
  }
}
