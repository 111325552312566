@import "../../styles/variables";

.Container {
  width: 100%;
  height: fit-content;
  z-index: 10;
}

.HeroSection {
  height: 80vh;
  width: 100%;
  background-color: $primary;
  z-index: 10;
  overflow: hidden;
  position: relative;
  padding-top: 140px;

  @include breakpoint(lg) {
    height: 60vh;
  }
  @include breakpoint(sm) {
    padding-top: 70px;
    height: 80vh;
  }
}
#HeroBackground {
  @include breakpoint(sm) {
    display: none;
  }
}
#MobileHeroBackground {
  display: none;
  @include breakpoint(sm) {
    display: block;
  }
}

.TextAnimation {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 160px;
  left: 100px;
  p {
    font-family: "Comic Neue", cursive;
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
  }
  @include breakpoint(xl) {
    top: 120px;
    left: 6px;
  }
  @include breakpoint(lg) {
    top: 100px;
    left: -24px;
  }
  @include breakpoint(lg) {
    p {
      font-size: 14px;
    }
  }
}

.HeroContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 730px;
  margin: 0 auto;
  position: relative;
  top: 95px;
  z-index: 10;
  h1 {
    color: #111;
    text-align: center;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    margin-bottom: 24px;
    @include breakpoint(sm) {
      font-size: 30px;
      line-height: 44px;
      margin-bottom: 18px;
    }
  }
  p {
    color: #111;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 80%;
    margin-bottom: 32px;
    @include breakpoint(sm) {
      width: 100%;
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
  @include breakpoint(sm) {
    width: 100%;
    padding: 0px 37px;
  }
}

#HeroDecor1 {
  position: absolute;
  top: 280px;
  left: 170px;
  z-index: 3;
  @include breakpoint(lg) {
    display: none;
  }
}
#HeroDecor2 {
  position: absolute;
  top: 240px;
  right: 170px;
  z-index: 3;
  @include breakpoint(lg) {
    width: 69.263px;
    height: 55.622px;
    transform: rotate(15deg);
    top: 500px;
    right: 30px;
  }
}
#HeroImage1 {
  position: absolute;
  z-index: 0;
  left: 60px;
  bottom: 10px;
  @include breakpoint(lg) {
    display: none;
  }
}
#HeroImage2 {
  position: absolute;
  bottom: 50px;
  transform: translate(-50%, 0%);
  left: 40%;
  z-index: 0;

  @include breakpoint(xxxl) {
    bottom: -100px;
  }
  @include breakpoint(sm) {
    img {
      width: 240px;
      height: 240px;
    }
    bottom: 30px;
    left: 25%;
  }
}
#HeroImage3 {
  position: absolute;
  z-index: 0;
  right: 0;
  bottom: 30px;
  @include breakpoint(lg) {
    display: none;
  }
}

.FAQSection {
  padding: 50px 178px;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  @include breakpoint(lg) {
    padding: 30px 24px;
  }
}

.FAQItem {
  margin-bottom: 20px;
  height: fit-content;
  width: 800px;

  @include breakpoint(lg) {
    width: 90%;
  }
  @include breakpoint(sm) {
    width: 100%;
  }
}

.Question {
  border-radius: 20px;
  background: rgba(244, 244, 244, 0.61);
  box-shadow: -4px 0px 15px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 25px 30px;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.Answer {
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  padding: 25px 0px;
  white-space: pre-line;
}

.GetInTouch {
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: #d4a8cf;
  gap: 22px;
  margin-top: 100px;

  h2 {
    font-size: 30px;
    text-align: center;
    @include breakpoint(sm) {
      font-size: 24px;
    }
  }
  p {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    @include breakpoint(sm) {
      font-size: 16px;
    }
  }
  button {
    border: none;
    border-radius: 40.682px;
    box-shadow: 0px 2.712px 5.424px 0px rgba(12, 178, 5, 0.35);
    background-color: $primary;
    width: fit-content;
    height: fit-content;
    padding: 20px 50px;
    color: #fff;
    border-radius: 30px;
    border: 1px solid var(--text-grdient, #22b14c);
    background: linear-gradient(
      93deg,
      #1ceb59 4.63%,
      rgba(78, 226, 121, 0.73) 105.65%
    );
    box-shadow: 0px -5px 6px 0px rgba(0, 0, 0, 0.25) inset,
      0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    text-align: center;
    text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    @include breakpoint(sm) {
      font-size: 16px;
    }
  }
}
