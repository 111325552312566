@import "../../../styles/variables";

.Questions {
  width: 100%;
  height: fit-content;
  position: relative;
}

.Question {
  position: absolute;
  width: 100%;
  padding: 38px 13px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #fff;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.25);
  text-align: start;
}

.OptionsWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  button {
    border-radius: 10px;
    border: 0.5px solid var(--gray-4, #bdbdbd);
    padding: 18px 15.17px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: default;
  }
}

#circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid $grey4;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}


.ButtonsWrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.RestrictAccessModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}

.RestrictAccessModalContentWrapper {
  position: relative;
  top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}