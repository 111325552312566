.Help {
  width: 100%;
  height: fit-content;
  padding: 30px;
}

.Container {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 50px;
}

.HelpMessage {
  background-color: #f3fdfd;
  padding-left: 13px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Wrapper {
  margin-top: 30px;
}

.ContactWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  gap: 10px;
}

.ContactItem {
  border-radius: 20px;
  border: 0.5px solid var(--info, #2f80ed);
  padding: 18px 50px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
