@import "../../styles/variables";

.Container {
  width: 100%;
  height: fit-content;
  z-index: 10;
  display: flex;
  flex-direction: column;
  h1,h2,h3,h4,h5,h6 {
    text-align: start;
  }
  li {
    text-align: start;
  }
}

.HeroSection {
  width: 100%;
  background-color: $primary;
  z-index: 10;
  position: relative;
  padding-top: 140px;
  @include breakpoint(sm) {
    padding-top: 70px;
  }
  @include breakpoint(lg) {
    height: 90vh;
  }
}
#HeroBackground {
  @include breakpoint(sm) {
    display: none;
  }
}
#MobileHeroBackground {
  display: none;
  @include breakpoint(sm) {
    display: block;
  }
}


.deleteYourData {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    overflow: scroll;
    h1 {
      font-size: 2em;
      margin-bottom: 10px;
    }
  
    p {
      font-size: 1.1em;
      line-height: 1.6;
    }
  
    ol {
      padding-left: 20px;
    }
  
    li {
      margin-bottom: 30px;
  
      h2 {
        font-size: 1.5em;
        margin-bottom: 10px;
      }
  
      ul {
        margin-left: 20px;
        li {
          list-style: disc;
        }
      }
  
      .imagePlaceholder {
        width: 100%;
        height: 200px;
        background-color: #eee;
        border: 1px dashed #ccc;
        text-align: center;
        line-height: 200px;
        color: #888;
        margin-top: 15px;
      }
    }
  }
  