@import "../../../styles/variables";

.Subject {
  background-color: #fff;
  padding: 50px 47px;
  border-radius: 29px;
  max-width: 500px;
  @include breakpoint(sm) {
    display: none;
  }
}

.GradeWrapper {
  display: flex;
  flex-direction: column;
}

.GradeItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 35px;
  padding: 30px 40px;
  border-bottom: 0.5px solid $grey4;
}

.ProfileLimitModal {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  padding: 20px;
}

.SubjectHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
}

.HeaderWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 12px 18px;
  border-radius: 9px;
  border: 1px solid $primary;
  cursor: pointer;  
  background: #fff;
  margin-right: 30px;
}

.Content {
  margin-top: 29px;
}

.ContentCard {
  width: 100%;
  height: fit-content;
  border-radius: 10px;
  background: var(--white, #fff);
  box-shadow: 0px 10px 3px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 36px 20px;
  position: relative;
  margin-top: 14px;
  gap: 40px;
}

#cardDecor1 {
  position: absolute;
  top: 0;
  left: 0;
}
#cardDecor2 {
  position: absolute;
  bottom: 0;
  right: 0;
}
#cardDecor3 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.ContentItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ContentTextWrapper {
  margin-left: 32px;
}
