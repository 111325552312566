@import "../../../../styles/variables";

.Button {
  cursor: pointer;
  display: flex;
  width: 100%;
  background-color: $primary;
  padding: 20px;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 30px;
  box-shadow: 0px 2px 5px 0px rgba(188, 255, 185, 0.54);
  background: var(
    --c,
    linear-gradient(93deg, #1ceb59 4.63%, rgba(78, 226, 121, 0.73) 105.65%)
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset,
    0px -5px 6px 0px rgba(0, 0, 0, 0.25) inset;
  color: #fff;

  text-transform: capitalize;
  text-align: center;
  text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  font-family: "ComicSans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &:hover,
  &:disabled {
    color: #fff;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      linear-gradient(93deg, #1ceb59 4.63%, rgba(78, 226, 121, 0.73) 105.65%);
  }
}

.white {
  border-radius: 30px;
  padding: 20px 0px;
  text-shadow: none;
  border: 1px solid var(--gray-5, #e0e0e0);
  background: var(--white, #fff);
  box-shadow: 0px -4px 5px 0px rgba(0, 0, 0, 0.25) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  font-size: 12px;
  &:hover,
  &:disabled {
    color: #fff;
    background: linear-gradient(
        0deg,
        rgba(232, 232, 232, 0.2) 0%,
        rgba(242, 242, 242, 0.2) 100%
      ),
      linear-gradient(93deg, rgba(255, 255, 255, 0.8) 4.63%, rgba(255, 255, 255, 0.73) 105.65%);
  }
}

.outlined {
  background: transparent;
  border: 2px solid #a1d5c8;
  color: $primary;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-shadow: none;
  box-shadow: none;
  &:hover {
    background: transparent;
  }
}

.PrevButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
}
