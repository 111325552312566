@import "../../styles/variables";

.CreateProfile {
  width: 100%;
  padding: 30px;
  min-height: 130vh;
  background: url("../../assets/images/authBackground2.jpg");
  background-size: cover;
}

.Container {
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 40px;
}

.Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  @include breakpoint(sm) {
    flex-direction: column;
    gap: 0px;
  }
}

.InputWrapper {
  width: 100%;
  margin-bottom: 30px;
  p {
    text-align: left;
  }
}

.GradeWrapper {
  display: flex;
  flex-direction: column;
}

.GradeItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 35px;
  padding: 30px 40px;
  border-bottom: 0.5px solid $grey4;
}
