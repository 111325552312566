@import "../../../styles/variables";

.Sidebar {
  background: $black1;
  padding: 0px 30px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  nav {
    margin-top: 98px;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: fit-content;
      height: fit-content;
      width: 100%;
      gap: 30px;
    }
    a {
      &:last-child {
        margin-bottom: 0px;
      }
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
          margin-right: 28px;
        }
        span {
          font-weight: 400;
          font-size: 18px;
          line-height: 23px;
          color: $white;
        }
      }
    }
  }
}

#Logo {
  width: 145px;
  height: 58px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

.UserDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 17px;
  color: #fff;
  align-items: center;
  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-transform: capitalize;
  }
}

.LogoutButton {
  color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 200px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

@media (max-width: 900px) {
  #wrapper {
    width: 100%;
    margin-bottom: 0px;
  }
  .LogoutButton {
    display: none;
  }
  .Sidebar {
    background: #fff;
    padding-bottom: 0px;
    flex-direction: row;
    padding: 10px;
    nav {
      margin-top: 0px;
      width: 100%;
      ul {
        width: 100%;
        flex: 1;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 4px;
        a {
          flex: 1 0 20%;
          li {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            padding: 5px 1%;
            svg {
              margin-right: 0px;
            }
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.Logout {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
  img {
    margin-bottom: 42px;
  }
}

.LogoutButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 14px;
}

#TextButton {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $grey4;
}
