@import "../../../styles/variables";

.ViewTopics {
  width: 100%;
  height: 100%;
  padding: 30px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
}
.Container {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}
.ProfileLimitModal {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    padding: 20px;
  }

.SubjectHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
}

.HeaderWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.GradeWrapper {
  display: flex;
  flex-direction: column;
}

.GradeItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 35px;
  padding: 30px 40px;
  border-bottom: 0.5px solid $grey4;
}

.TopicsWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.TopicSkeleton {
  height: 92px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.Empty {
  display: flex;
  flex-direction: column;
  align-items: center;
}
