@import "../../../../styles/variables";

.LessonLibraryItem {
    display: flex;
    flex: 1;
    background-color: $primary;
    border-radius: 9.382px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12.48px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    
}
p#text {
    font-size: 20px;
    @include breakpoint(sm) {
        font-size: 13.13px;
    }
}