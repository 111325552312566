.Signup {
  overflow-y: scroll;
  height: 83vh;
  padding: 0px 30px;
  padding-bottom: 100px;
}

.AuthHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 50px;
}

.Row {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 30px;
  margin-bottom: 30px;
  .InputWrapper {
    margin-bottom: 0;
  }
}

.InputWrapper {
  margin-bottom: 30px;
  flex: 1;
  p {
    text-align: start;
  }
}

@media (max-width: 500px) {
  .Row {
    flex-direction: column;
  }
}
