@font-face {
  font-family: "ComicSans";
  src: url("./assets/ComicSansMS3.ttf");
}

html, body {
  scroll-behavior: smooth;
}

.App {
  position: relative;
  z-index: 1;
}

* {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  color: currentColor;
  box-sizing: border-box;
}
p {
  text-align: start;
}

.active li #navtext {
  color: #D0ED76;
}
.active div {
  background-color: #D0ED76;
  color: #000;
}
#navtext {
  color: #fff;
}
.react-player {
  padding-top: 56.25%;
  position: relative;
}
span.swiper-pagination-bullet {
  background-color: #D9D9D9;
  height: 10px;
  width: 10px;
  opacity: 1;
}
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #22B14C;
  height: 10px;
  width: 10px;
  opacity: 1;
}
.react-player > div {
  position: absolute;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
