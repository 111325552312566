@import "../../styles/variables";

.Downloads {
  padding: 30px 20px;
  width: 100%;
}
.Container {
  margin: auto;
  max-width: 1000px;
  margin-top: 40px;
}

.HeaderWrapper {
  display: flex;
  flex-direction: row-reverse;
}

.Empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.DownloadsWrapper {
  margin-top: 31px;
}

.DownloadItem {
  width: 100%;
  flex: 1 0 33%;
  cursor: pointer;
  img {
    height: 300px;
    width: 300px;
    object-fit: cover;
    border-radius: 10px;
    border: 1px solid black;
  }
}

.GradeWrapper {
  display: flex;
  flex-direction: column;
}

.GradeItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 35px;
  padding: 30px 40px;
  border-bottom: 0.5px solid $grey4;
}

