@import "../../styles/variables";

.Container {
  width: 100%;
  height: fit-content;
  z-index: 10;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: start;
  }
}

.HeroSection {
  height: 100vh;
  width: 100%;
  background-color: $primary;
  z-index: 10;
  overflow: hidden;
  position: relative;
  padding-top: 140px;

  @include breakpoint(xl) {
    height: 1000px;
  }
  @include breakpoint(l) {
    height: 800px;
  }
  @include breakpoint(md) {
    height: 90vh;
  }
  @include breakpoint(sm) {
    padding-top: 70px;
    height: 700px;
  }
}
#HeroBackground {
  @include breakpoint(sm) {
    display: none;
  }
}
#MobileHeroBackground {
  display: none;
  @include breakpoint(sm) {
    display: block;
  }
}

.HeroContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 730px;
  margin: 0 auto;
  position: relative;
  top: 95px;
  z-index: 10;
  h4 {
    color: #10411f;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 7.68px;
    text-transform: uppercase;
    margin-bottom: 10px;
    @include breakpoint(sm) {
      font-size: 14px;
    }
  }
  h1 {
    color: #111;
    text-align: center;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
    margin-bottom: 24px;
    @include breakpoint(sm) {
      font-size: 30px;
      line-height: 44px;
      margin-bottom: 18px;
    }
  }
  p {
    color: #111;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 80%;
    margin-bottom: 32px;
    @include breakpoint(sm) {
      width: 100%;
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
  @include breakpoint(sm) {
    width: 100%;
    padding: 0px 37px;
  }
}

.ButtonsWrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  button {
    cursor: pointer;
    border: none;
    padding: 19px 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 11px;
    background: #fff;
    border-radius: 10px;
    div {
      display: flex;
      flex-direction: column;
      p {
        font-size: 14px;
        margin-bottom: 0;
        text-align: start;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 0.14px;
        width: fit-content;
      }
      h3 {
        text-align: start;
        color: var(--black, #111);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        font-family: "Lato", system-ui, -apple-system, BlinkMacSystemFont,
          "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
          "Helvetica Neue", sans-serif;
      }
    }
    @include breakpoint(sm) {
      border-radius: 6.471px;
      padding: 8px 16px;
      img {
        height: 19px;
        width: 19px;
      }
      div {
        p {
          font-size: 9px;
        }
        h3 {
          font-size: 13px;
        }
      }
    }
  }
}

#GoogleButton {
  background: transparent;
  border: 1px solid var(--white, #fff);
  div {
    p {
      color: #fff;
    }
    h3 {
      color: #fff;
    }
  }
}

.TextAnimation {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 160px;
  left: 100px;
  p {
    font-family: "Comic Neue", cursive;
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
  }
  @include breakpoint(xl) {
    top: 120px;
    left: 6px;
  }
  @include breakpoint(lg) {
    top: 100px;
    left: -24px;
  }
  @include breakpoint(lg) {
    p {
      font-size: 14px;
    }
  }
}

#HeroDecor1 {
  position: absolute;
  top: 280px;
  left: 170px;
  z-index: 3;
  @include breakpoint(xl) {
    display: none;
  }
}
#HeroDecor2 {
  position: absolute;
  top: 240px;
  right: 170px;
  z-index: 3;
  @include breakpoint(xl) {
    width: 69.263px;
    height: 55.622px;
    transform: rotate(15deg);
    top: 500px;
    right: 30px;
  }
}
#HeroImage1 {
  position: absolute;
  z-index: 0;
  left: 60px;
  bottom: 100px;
  @include breakpoint(xxxl) {
    left: 50px;
    bottom: 50px;
  }
  @include breakpoint(lg) {
    display: none;
  }
}
#HeroImage2 {
  position: absolute;
  bottom: 50px;
  transform: translate(-50%, 0%);
  left: 40%;
  z-index: 0;
  display: none;

  @include breakpoint(xxxl) {
    left: 40%;
    bottom: -25%;
  }
  @include breakpoint(xxl) {
    left: 35%;
    bottom: -150px;
  }
  @include breakpoint(l) {
    left: 30%;
    bottom: 0px;
  }
  @include breakpoint(sm) {
    img {
      width: 200px;
      height: 200px;
    }
    bottom: 0%;
    left: 25%;
  }
}
#HeroImage3 {
  position: absolute;
  z-index: 0;
  right: 0;
  bottom: 100px;
  @include breakpoint(xxxl) {
    bottom: 50px;
  }
  @include breakpoint(lg) {
    display: none;
  }
}

.AboutSection {
  padding: 130px 100px;
  @include breakpoint(lg) {
    padding: 70px 30px;
  }
}
.AboutContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
  gap: 70px;
  @include breakpoint(xl) {
    flex-direction: column;
  }
}

.AboutContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include breakpoint(xl) {
  }
  h4 {
    color: #2db555;
    font-feature-settings: "liga" off;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    @include breakpoint(sm) {
      font-size: 11px;
    }
  }
  h1 {
    color: var(--Neutral-900, #111827);
    font-feature-settings: "liga" off;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -0.48px;
    @include breakpoint(sm) {
      font-size: 28px;
      line-height: 34.596px;
    }
  }
  p {
    color: var(--black, #111);
    font-feature-settings: "liga" off;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    @include breakpoint(xxl) {
      margin-top: 24px;
      margin-bottom: 30px;
    }
    @include breakpoint(sm) {
      margin-top: 13px;
      margin-bottom: 17.3px;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .ButtonsWrapper {
    #AppleButton {
      background-color: #111;
      div {
        p {
          color: #fff;
          margin-top: 0px;
          margin-bottom: 0px;
        }
        h3 {
          color: #fff;
        }
      }
    }
    #GoogleButton {
      background-color: transparent;
      border: 1px solid var(--black, #111);
      div {
        p {
          color: #111;
          margin-top: 0px;
          margin-bottom: 0px;
        }
        h3 {
          color: #111;
        }
      }
    }
  }
}

#AboutImage {
  object-fit: cover;
  width: 50%;
  @include breakpoint(xl) {
    width: 100%;
  }
}

.FeatureSection {
  background-color: $primary;
  padding: 130px 100px;
  position: relative;
  overflow: hidden;
  @include breakpoint(xl) {
    padding: 80px 24px;
    padding-bottom: 150px;
  }
}

.FeatureContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 145px;
  @include breakpoint(lg) {
    flex-direction: column;
    gap: 33px;
  }
  .TitleWrap {
    max-width: 563px;
    flex: 1;
    @include breakpoint(lg) {
      max-width: 100%;
    }
    h4 {
      color: rgba(0, 0, 0, 0.6);
      font-feature-settings: "liga" off;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 1.8px;
      text-transform: uppercase;
      @include breakpoint(sm) {
        font-size: 11px;
        letter-spacing: 1.1px;
      }
    }
    h1 {
      color: var(--white, #fff);
      font-feature-settings: "liga" off;
      font-size: 44px;
      font-weight: 700;
      line-height: 58px;
      letter-spacing: -0.88px;
      @include breakpoint(sm) {
        color: #000;
        text-edge: cap;
        font-feature-settings: "liga" off;
        font-family: Poppins;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -0.56px;
      }
    }
  }
  .FeatureMainContent {
    flex: 1;
    max-width: 556px;
    color: #000;
    font-feature-settings: "liga" off;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    @include breakpoint(lg) {
      max-width: 100%;
    }
    @include breakpoint(sm) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.FeatureCardsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 60px;
  @include breakpoint(lg) {
    flex-wrap: wrap;
    justify-content: center;
  }
  @include breakpoint(sm) {
    flex-direction: column;
    align-items: center;
  }
}
.FeatureCard {
  height: fit-content;
  width: fit-content;
  position: relative;
  img {
    z-index: 0;
  }
  @include breakpoint(xxl) {
    img {
      width: 328px;
      height: 338px;
    }
  }
  .FeatureCardContent {
    position: absolute;
    z-index: 10;
    padding: 40px 28px;
    width: 100%;
    @include breakpoint(xxl) {
      padding: 30px;
    }
    .CardNumbering {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      .NumberCircle {
        width: 40px;
        height: 40px;
        border-radius: 28.958px;
        border: 0.965px solid var(--text-grdient, #22b14c);
        background: var(
          --c,
          linear-gradient(
            93deg,
            #1ceb59 4.63%,
            rgba(78, 226, 121, 0.73) 105.65%
          )
        );
        display: flex;
        align-items: center;
        justify-content: center;
      }
      h4 {
        color: var(--black, #111);
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
}
.CardContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  p {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin-top: 6px;
  }
  @include breakpoint(xxl) {
    img {
      width: 175.5px;
      height: 175.5px;
    }
    p {
      font-size: 14px;
      margin-top: 0px;
    }
  }
}
.FeatureCard:nth-child(2) {
  position: relative;
  top: 36px;
}
.FeatureCard:nth-child(3) {
  position: relative;
  top: 76px;
}

#FeatureDecor {
  position: absolute;
  left: 0;
  bottom: 0px;
  @include breakpoint(sm) {
    display: none;
  }
}
#MobileFeatureDecor {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0px;
  @include breakpoint(sm) {
    display: block;
  }
}

.OurOfferSection {
  padding: 130px 100px;
  padding-bottom: 0px;
  h1 {
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px;
    color: #111827;
    text-align: center;
    margin-bottom: 118px;
  }
  @include breakpoint(xl) {
    padding: 70px 24px;
    padding-bottom: 30px;
    h1 {
      font-size: 30px;
    }
  }
}

.OfferWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 30px;
  row-gap: 122px;
  margin: 0px;
  @include breakpoint(sm) {
    row-gap: 90px;
  }
}

.OfferItem {
  max-width: 390px;
  height: 376px;
  border-radius: 16px;
  background: #def7e6;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include breakpoint(xl) {
    max-width: 300px;
  }
  @include breakpoint(sm) {
    max-width: 100%;
    height: 300px;
  }
  img {
    margin-bottom: 28px;
    position: relative;
    top: -66px;
    @include breakpoint(sm) {
      max-width: 100%;
      height: fit-content;
      height: 100px;
      width: 100px;
      top: -50px;
    }
  }
  h4 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    color: var(--Neutral-900, #111827);
    text-align: center;
    position: relative;
    margin-bottom: 8px;
    @include breakpoint(sm) {
      font-size: 20px;
      line-height: normal;
    }
  }
  p {
    color: var(--black, #111);
    text-align: center;
    font-feature-settings: "liga" off;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    position: relative;
    @include breakpoint(sm) {
      font-size: 15px;
      line-height: normal;
    }
  }
}

.OfferItemContent {
  height: 100%;
  min-height: 270px;
  overflow: auto;
  position: relative;
  top: -66px;
  padding: 0px 20px;
}

.InfoSection {
  padding: 130px 0px;
  padding-bottom: 0px;
  @include breakpoint(xl) {
    padding: 70px 0px;
    padding-bottom: 0px;
  }
}
.GetStartedContainer {
  display: flex;
  flex-direction: row;
  gap: 46px;
  justify-content: space-between;
  margin-bottom: 60px;
  padding: 0px 100px;
  height: fit-content;
  @include breakpoint(xl) {
    padding: 0px 24px;
  }
  @include breakpoint(lg) {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 26px;
  }
}

#InfoCardDecor {
  @include breakpoint(sm) {
    width: 123.828px;
    height: 137.924px;
  }
}

.GetStarted {
  min-height: 486px;
  border-radius: 60px;
  background: #def7e6;
  padding: 56px 52px;
  padding-bottom: 44px;
  flex: 7;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include breakpoint(sm) {
    min-height: fit-content;
    height: 486px;
    border-radius: 24px;
    padding: 40px 24px;
  }
  h2 {
    color: var(--Neutral-900, #111827);
    font-feature-settings: "liga" off;
    font-size: 44px;
    font-weight: 700;
    line-height: 54px;
    margin-bottom: 20px;
    @include breakpoint(sm) {
      font-size: 21px;
      line-height: 26px;
      margin-bottom: 12px;
    }
    span {
      color: var(--primary, #22b14c);
    }
  }
  p {
    color: var(--black, #111);
    font-feature-settings: "liga" off;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 28px;
    @include breakpoint(sm) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 16px;
    }
  }
  button {
    border-radius: 40.682px;
    border: 1.356px solid var(--text-grdient, #22b14c);
    background: var(
      --c,
      linear-gradient(93deg, #1ceb59 4.63%, rgba(78, 226, 121, 0.73) 105.65%)
    );
    padding: 16px 31px;
    color: var(--black, #111);
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    @include breakpoint(sm) {
      font-size: 14px;
      padding: 9px 18px;
    }
  }
}

.Learning {
  padding: 73px 50px;
  flex-grow: 1;
  border-radius: 60px;
  background: #ffd6d6;
  flex: 5;
  position: relative;
  overflow: hidden;
  @include breakpoint(sm) {
    border-radius: 24px;
    padding: 40px 24px;
  }
  h3 {
    color: var(--Neutral-900, #111827);
    font-feature-settings: "liga" off;
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    margin-bottom: 22px;
    @include breakpoint(sm) {
      font-size: 21px;
      line-height: 26px;
      margin-bottom: 15.52px;
    }
  }
  p {
    color: var(--black, #111);
    font-feature-settings: "liga" off;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    @include breakpoint(sm) {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.Intro {
  padding: 68px 61.68px;
  border-radius: 60px;
  background: #fff7d7;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  gap: 80px;
  margin: 0px 100px;
  @include breakpoint(xl) {
    margin: 0px 24px;
  }
  @include breakpoint(lg) {
    flex-direction: column;
    align-items: flex-start;
  }
  @include breakpoint(sm) {
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 21px;
    border-radius: 24px;
    gap: 32px;
  }
}

#IntroDecor {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  @include breakpoint(sm) {
    display: none;
  }
}

#MobileIntroDecor {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  @include breakpoint(sm) {
    display: block;
  }
}

#IntroImage {
  z-index: 10;
  position: relative;
  @include breakpoint(xl) {
    width: 265.668px;
    height: 204px;
  }
}

.IntroContent {
  z-index: 10;
  position: relative;
  h2 {
    color: var(--Neutral-900, #111827);
    font-feature-settings: "liga" off;
    font-size: 44px;
    font-weight: 700;
    line-height: 54px;
    margin-bottom: 24px;
    @include breakpoint(sm) {
      font-size: 28px;
      line-height: 42px;
      margin-bottom: 14px;
    }
  }
  p {
    color: var(--black, #111);
    font-feature-settings: "liga" off;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    @include breakpoint(sm) {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.VideoLibrarySection {
  padding: 88px 100px;
  background-color: $primary;
  padding-bottom: 100px;
  margin-top: 100px;
  h1 {
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px;
    color: #111827;
    text-align: center;
    margin-bottom: 118px;
    width: 600px;
    margin: 0 auto;
  }
  @include breakpoint(xl) {
    padding: 70px 24px;
    padding-bottom: 30px;
    h1 {
      font-size: 30px;
      width: 400px;
    }
  }
  @include breakpoint(md) {
    h1 {
      width: 90%;
    }
  }
}

.VideosContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 100px;
  margin-top: 85px;
  @include breakpoint(xl) {
    gap: 40px;
  }
  @include breakpoint(lg) {
    flex-direction: column;
    gap: 40px;
  }
}

.PlayerWrapper {
  width: 500px;
  height: 400px;
  background-color: #000;
  z-index: 10;
  position: relative;
  @include breakpoint(xl) {
    width: 450px;
  }
  @include breakpoint(lg) {
    width: 100%;
  }
}

.VideoItemWrapper {
  height: fit-content;
  width: fit-content;
  position: relative;

  @include breakpoint(lg) {
    width: 100%;
  }
}

#VideoDecor1 {
  position: absolute;
  top: 0;
  z-index: 0;
  right: 10;
  @include breakpoint(lg) {
    display: none;
  }
}
#VideoDecor2 {
  position: absolute;
  top: 0;
  top: 10px;
  left: 10px;
  @include breakpoint(lg) {
    display: none;
  }
}
#VideoDecor3 {
  position: absolute;
  top: 0;
  top: 20px;
  left: 20px;
  @include breakpoint(lg) {
    display: none;
  }
}

.Discover {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 145px;
  margin-top: 130px;
  padding: 0px 100px;
  @include breakpoint(xl) {
    padding: 0px 24px;
  }
  @include breakpoint(lg) {
    flex-direction: column;
    gap: 33px;
    margin-top: 100px;
  }
  .TitleWrap {
    max-width: 563px;
    flex: 1;
    @include breakpoint(lg) {
      max-width: 100%;
    }
    h4 {
      color: rgba(0, 0, 0, 0.6);
      font-feature-settings: "liga" off;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 1.8px;
      text-transform: uppercase;
      @include breakpoint(sm) {
        font-size: 11px;
        letter-spacing: 1.1px;
      }
    }
    h1 {
      color: var(--white, #111);
      font-feature-settings: "liga" off;
      font-size: 44px;
      font-weight: 700;
      line-height: 58px;
      letter-spacing: -0.88px;
      @include breakpoint(sm) {
        color: #000;
        text-edge: cap;
        font-feature-settings: "liga" off;
        font-family: Poppins;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -0.56px;
      }
    }
  }
  .DiscoverMainContent {
    flex: 1;
    max-width: 556px;
    color: #000;
    font-feature-settings: "liga" off;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    @include breakpoint(lg) {
      max-width: 100%;
    }
    @include breakpoint(sm) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.CTA {
  padding: 105px 100px;
  background: #def7e6;
  position: relative;
  overflow: hidden;
  @include breakpoint(lg) {
    padding: 120px 20px;
    padding-bottom: 220px;
  }
}
#CTAImage {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @include breakpoint(lg) {
    display: none;
  }
}
#MobileCTAImage {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  @include breakpoint(lg) {
    display: block;
  }
}

.CTAWrapper {
  position: relative;
  z-index: 10;
  width: 559px;
  @include breakpoint(lg) {
    width: 100%;
    position: relative;
    top: 100px;
  }
  .ButtonsWrapper {
    #AppleButton {
      background-color: #111;
      div {
        p {
          color: #fff;
          margin-top: 0px;
          margin-bottom: 0px;
        }
        h3 {
          color: #fff;
        }
      }
    }
    #GoogleButton {
      background-color: transparent;
      border: 1px solid var(--black, #111);
      div {
        p {
          color: #111;
          margin-top: 0px;
          margin-bottom: 0px;
        }
        h3 {
          color: #111;
        }
      }
    }
  }
  h1 {
    color: var(--black, #111);
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.8px;
    margin-bottom: 4px;
    @include breakpoint(sm) {
      font-size: 28px;
    }
  }
  p {
    color: var(--black, #111);
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 56px;
    @include breakpoint(sm) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }
}
