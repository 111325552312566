@import "../../styles/variables";

.Analytics {
  padding: 30px;
  width: 100%;
}

.Container {
  display: flex;
  margin: auto;
  max-width: 800px;
  margin-top: 40px;
  align-items: center;
  flex-direction: column;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 30px;
}

.AvatarWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;
}

.Avatar {
  padding: 5px;
  border-radius: 50%;
  border: 7px solid #59E1BF;
  margin-bottom: 6px;
  img {
    width: 77px;
    height: 77px;
    border-radius: 50%;
  }
}

.ItemsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 23px;
  gap: 13px;
  width: 100%;
}
.Chart {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 23px;
  position: relative;
}

.AnalyticItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 7px;
  .ItemTextWrapper {
    text-align: start;
  }
  padding: 17px 5px;
  padding-left: 11px;
  min-width: 114px;
  border-radius: 10px;
  border: 0.5px solid $primary;
}

.ChartTextWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  margin-top: 60px;
}
.ManageProfileModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  padding: 20px;
}

.ProfilesAvatarsList {
  margin-top: 36px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}

.ManageProfileModalItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.ManageProfileItemAvatarWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
