@import "../..//styles/variables";

.Payment {
    width: 100%;
    min-height: 100vh;
    background-image: url("../../assets/images/authBackground2.jpg");
    padding: 30px;
}

.Container {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 50px;
}

.ImageWrapper {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 40px;
}

.Wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.PaymentItem {
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px 28px;
    border-radius: 10px;
    border: 0.5px solid var(--gray-4, #BDBDBD);
}
#active {
    color: #fff;
    background-color: $primary;
    border: none;
    p {
        color: #fff;
    }
}
.PayWithVoucher {
    width: 100%;
    min-height: 100vh;
    padding: 30px;
}

.VoucherInput {
    margin-top: 36px;
    margin-bottom: 50px;
}