@import "../../../../styles/variables";

.LeaderBoardItem {
  width: 100%;
  border-radius: 10px;
  border: 0.5px solid var(--t2, #c8b6c6);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0px;
  padding-left: 13px;
  padding-right: 25px;
  margin-bottom: 22px;
  background: #fff;
}

.Wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Avatar {
  margin: 0px 18.5px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  object-fit: cover;
}

#Score {
    font-weight: 600;
    font-size: 14px;
    color: $primary;
}

