.LeaderBoard {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.Header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Wrapper {
  padding: 22px 0px;
}

.ItemSkeleton {
  height: 78px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 22px;
}

.Empty {
  display: flex;
  flex-direction: column;
  align-items: center;
}
