.TopicItem {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background: var(--white, #fff);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 10px;
}

.Wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#Media {
  margin-right: 14px;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.TextWrapper {
  text-align: start;
}
