@import "../../../../styles/variables";

.Toast {
  width: 80%;
  padding: 20px;
  height: fit-content;
  position: fixed;
  z-index: 200;
  top: -100px;
  left: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  color: #fff;
  font-size: 24px;
  transition: top 0.3s ease-in-out;
  animation: slideIn 0.3s ease-in-out;
}

@keyframes slideIn {
    from {
      top: -100px;
    }
    to {
      top: 20px;
    }
  }

.show {
    top: 20px;
    transition: 0.3s;
}


#success {
  background: $success;
}

#error {
  background: $error;
}

#info {
    background: $info;
}