@import "../../styles/variables";

.Guardian {
  // background: url("../../assets/images/authBackground2.jpg");
  min-height: 100vh;
  width: 100%;
  position: relative;
  z-index: 100;
  padding-bottom: 30px;
}

.ShareButton {
  padding: 10.5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 21px;
  background: #dffede;
  box-shadow: 0px 2.625px 7.875px 0px rgba(0, 0, 0, 0.25);
  @include breakpoint(sm) {
    padding: 10.5px 13px;
  }
}

.MainHeader {
  background-color: #fff;
  padding: 22px 80px;
  @include breakpoint(sm) {
    padding: 20px 20px;
  }
}

.Container {
  padding: 0px 24px;
  max-width: 1250px;
  margin: 0 auto;
  height: fit-content;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
  @include breakpoint(sm) {
    flex-direction: column;
  }
}

.SubjectsWrapper {
  display: none;
  @include breakpoint(sm) {
    display: block;
  }
}

.CreateProfilePrompt {
  padding: 0px 32px;
  background-color: #0088de;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 13px;
  height: fit-content;
  border-radius: 20px;
  overflow: hidden;
  @include breakpoint(sm) {
    padding: 20px 14px;
  }
}

.CreateProfilePromptWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ProfileLimitModal {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  padding: 20px;
}

.SubjectsWrapper {
  margin-top: 37px;
}

.SubjectsHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.GradeWrapper {
  display: flex;
  flex-direction: column;
}

.GradeItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 35px;
  padding: 30px 40px;
  border-bottom: 0.5px solid $grey4;
}

.Subjects {
  margin-top: 21px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.Subject {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.Share {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 23.75px 38px;
  border-radius: 26.25px;
  background: rgba(143, 164, 12, 0.13);
  margin-top: 36px;
  @include breakpoint(sm) {
    padding: 23px 20px;
  }
}

.PurchaseProfile {
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  border-radius: 20px;
  background: #f0fafd;
  position: relative;
  overflow: hidden;
  @include breakpoint(sm) {
    padding: 20px;
  }
}

.PurchaseProfileWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 11px;
  z-index: 10;
  position: relative;
}

.ManageProfileModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  padding: 20px;
}

.ManageProfileModalItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.ProfilesAvatarsList {
  margin-top: 36px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
}

.ManageProfileItemAvatarWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
