@import "../../../../styles/variables";

.UserHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.Avatar {
    height: 50px;
    width: 50px;
    border-radius: 20px;
    object-fit: cover;
    border: 2px solid $primary;
    background-color: #fff;
}