$primary: #22B14C;
$secondary: #D0ED76;

$info: #2F80ED;
$success: #51E38F;
$warning: #E2B93B;
$error: #E22C2C;

$math: #FF8282;
$sci: #9747FF;

$black1: #111111;
$black2: #1D1D1D;
$black3: #282828;
$white: #FFFFFF;

$grey1: #333333;
$grey2: #4F4F4F;
$grey3: #828282;
$grey4: #BDBDBD;
$grey5: #E0E0E0;

@mixin heading {
    text-align: center;
    font-weight: 600;
    margin-bottom: 64px;
    font-size: 39.06px;
    &::after {
      content: "";
      display: block;
      height: 16px;
      background-repeat: no-repeat;
      background-position: center bottom;
    }
  }

  $breakpoints: (
    xs: (min: 375px,
        max: 374.98px,
    ),
    sm: (min: 600px,
        max: 599.98px,
    ),
    md: (min: 768px,
        max: 767.98px),
    lg: (min: 992px,
        max: 991.98px),
    xl: (min: 1200px,
        max: 1199.98px),
    xxl: (min: 1400px,
        max: 1399.98px,
    ),
    xxxl: (min: 1600px,
        max: 1599.98px,
    )
);

@mixin breakpoint($breakpoint, $direction: max) {

    //check if the supplied breakpoint exists in our breakpoits map
    @if map-has-key($breakpoints, $breakpoint) {
        $breakpoint-values: map-get($breakpoints, $breakpoint);
        $breakpoint-min: map-get($breakpoint-values, min);
        $breakpoint-max: map-get($breakpoint-values, max);

        //check if we are writing styles for larger or smaller screens
        @if $direction ==max {
            @media (max-width: $breakpoint-min) {
                @content;
            }
        }

        @else {
            @media (max-width: $breakpoint-max) {
                @content;
            }
        }

        // use the custom value if the breakpoint is not part of the pre-defined list
    }

    @else {

        @if $direction ==max {
            @media (max-width: $breakpoint) {
                @content;
            }
        }

        @else {
            @media (max-width: $breakpoint) {
                @content;
            }
        }
    }
}