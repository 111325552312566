.Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.GoBackButton {
    display: flex;
    width: 36px;
    padding: 6px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
  .TextWrapper {
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 0;
  }