.GuardianDashboard {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  background: #ededee;
}

#decor1 {
  position: absolute;
  left: 0;
  top: 10%;
  z-index: 0;
}
#decor2 {
  position: absolute;
  bottom: 0;
  left: 30%;
  z-index: 0;
}
#decor3 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
}
.PageWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 50;
  overflow-y: scroll;
}
