@import "../../styles/variables";

.Share {
  width: 100%;
  height: fit-content;
  padding: 30px;
}

.Container {
  margin: auto;
  max-width: 1000px;
  margin-top: 40px;
  align-items: flex-start;
}

.Wrapper {
  text-align: start;
}

.CopyWrapper {
  border-radius: 10px;
  background: rgba(225, 254, 224, 0.54);
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8.5px 28px;
  justify-content: space-between;
  margin-top: 60px;
  button {
    color: $primary;
  }
  @include breakpoint(sm) {
    width: 100%;
  }
}

.DownloadApp {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    margin-top: 50px;
    background-color: #EAC8E6;
    gap: 34px;
    padding: 0px 18px;
    padding-top: 20px;
    align-items: center;
    justify-content: space-between;
}
