.ResetPassword {
  padding: 0px 30px;
}
.AuthHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 50px;
}

.InputWrapper {
  margin-bottom: 30px;
  p {
    text-align: left;
  }
}
