.VerifyEmail {
    padding: 0px 30px;
    display: flex;
    height: 75vh;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .AuthHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 50px;
  }
  
  .InputWrapper {
    margin-bottom: 30px;
    p {
      text-align: left;
    }
  }

  .Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 60px;
  }

  @media(max-width: 500px) {
    .Wrapper {
        padding: 0px 0px;
    }
  }
  