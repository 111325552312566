@import "../../../../styles/variables";

.Footer {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 27px 100px;
  gap: 120px;
  justify-content: space-between;
  @include breakpoint(xl) {
    align-items: flex-start;
    justify-content: space-between;
    padding: 26px 20px;
    gap: 42px;
  }
  @include breakpoint(md) {
    #Logo {
      width: 58.047px;
      height: 57.814px;
    }
  }
}

.Wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 230px;
  @include breakpoint(xl) {
    flex-direction: column-reverse;
    gap: 45px;
    align-items: flex-end;
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 70px;
    @include breakpoint(md) {
        gap: 16px;
    }
    li {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @include breakpoint(md) {
        color: var(--black, #111);
        font-size: 11px;
        font-weight: 400;
      }
    }
  }
}

.Socials {
  display: flex;
  flex-direction: row;
  gap: 48px;
  @include breakpoint(sm) {
    display: none;
  }
  @include breakpoint(md) {
    gap: 30px;
}
}

.MobileSocials {
  display: none;
  @include breakpoint(sm) {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}
