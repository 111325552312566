.ChangePassword {
    width: 100%;
    padding: 30px;
  }
  
  .Container {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    padding: 0px 0px;
  }
  
  .AvatarWrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .EditAvatar {
    position: relative;
  }
  
  .Form {
      width: 100%;
      margin-top: 30px;
  }
  
  .InputWrapper {
      margin-bottom: 30px;
  }