@import "../../styles/variables";

.Container {
    width: 100%;
    height: fit-content;
    z-index: 10;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: start;
    }
  }

  .HeroSection {
    height: 100vh;
  width: 100%;
  background-color: $primary;
  z-index: 10;
  overflow: hidden;
  position: relative;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    font-size: 60px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    @include breakpoint(md) {
        font-size: 30px;
    }
  }
  }