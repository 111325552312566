.SubscriptionPlanItem {
  border-radius: 10px;
  width: 317px;
  height: 78px;
  background-color: #fff;
  padding: 15px 10px;
  padding-right: 29px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 20px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: -13px 13px 26px #f0f0f0, 13px -13px 26px #ffffff;
  overflow: hidden;
}

.Wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  gap: 20px;
}
