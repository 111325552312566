.LessonItem {
  display: flex;
  flex-direction: row;
  height: 97px;
  width: 100%;
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 10px;
}
.Media {
  display: flex;
  flex: 3;
  height: 100%;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.Content {
  display: flex;
  flex: 7;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid var(--gray-4, #bdbdbd);
  padding: 0px 13px;
}
