@import "../../../styles/variables";

.Testimonials {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  button {
    border: none;
    background: transparent;
    position: absolute;
    top: 250px;
    cursor: pointer;
    z-index: 100;
    @include breakpoint(sm) {
        top: 200px;
    }
  }
}

#PrevButton {
  position: absolute;
  left: 20px;
  @include breakpoint(sm) {
    img {
      width: 20.747px;
      height: 41.493px;
    }
  }
}
#NextButton {
  position: absolute;
  right: 20px;
  @include breakpoint(sm) {
    img {
      width: 20.747px;
      height: 41.493px;
    }
  }
}

.TestimonialItem {
  padding: 40px;
  width: 579px;
  height: 414px;
  border-radius: 24px;
  border: 1.104px solid #e2e8f0;
  background: #fff;
  box-shadow: 0px 18px 25px -5px rgba(0, 0, 0, 0.07),
    0px 0px 11.038px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include breakpoint(xl) {
    margin: 0 auto;
  }
  @include breakpoint(lg) {
    width: 300px;
    height: 300.5px;
    padding: 20.59px;
  }
  p {
    color: #161c2d;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 14px;
    margin-top: 20px;
    @include breakpoint(lg) {
      font-size: 13.013px;
      line-height: 17.16px;
    }
  }
  h5 {
    color: var(--gry-2, #4f4f4f);
    font-size: 17px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: -0.2px;
    @include breakpoint(lg) {
      font-size: 10.225px;
      line-height: 13.728px;
      letter-spacing: -0.104px;
    }
  }
  h6 {
    color: var(--gry-2, #4f4f4f);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.2px;
    @include breakpoint(lg) {
      font-size: 8.225px;
      letter-spacing: -0.104px;
    }
  }
}
