@import "../../../styles/variables";

.Lessons {
  height: 100vh;
  width: 100%;
  background-color: #59e1bf;
  overflow: auto;
  transition: 0.3s;
}

.Header {
  padding: 30px 20px;
  h4 {
    color: #fff;
  }
}

#SwiperWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 36px 16px;
  padding-bottom: 70px;
  padding-top: 0px;
  margin-top: 5%;
}

#SwiperItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

#LearningGif {
  width: 359px;
  height: 300px;
  margin: 0 auto;
}
