.ProfileItem {
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--gray-4, #bdbdbd);
  padding: 18px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: #fff;
}

.Wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}
