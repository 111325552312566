@import "../../../../styles/variables";

.Header {
  position: absolute;
  width: 80%;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 100px;
  transform: translate(-50%, 0%);
  left: 50%;
  top: 50px;
  z-index: 15;
  padding: 12px 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 80px;
    list-style: none;
    li {
      list-style: none;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      color: #111;
    }
  }
  @include breakpoint(xl) {
    border-radius: 0px;
    width: 100%;
    top: 0;
  }
}

.Logo {
  margin-left: 28px;
}

.MobileLogo {
  display: none;
}

.BurgerIcon {
  display: none;
}

.MobileMenu {
  height: 100vh;
  position: fixed;
  width: 100%;
  background-color: #64c982;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    list-style: none;
    li {
      list-style: none;
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      color: #111;
    }
  }
}

.HeaderButton {
  border-radius: 40.682px;
  border: 1.356px solid var(--text-grdient, #22b14c);
  background: var(
    --c,
    linear-gradient(93deg, #1ceb59 4.63%, rgba(78, 226, 121, 0.73) 105.65%)
  );
  padding: 20px 50px;
  color: var(--black, #111);
  text-align: center;
  font-family: Poppins;
  font-size: 13.561px;
  font-style: normal;
  cursor: pointer;
  font-weight: 700;
}

@media (max-width: 1200px) {
}

@media (max-width: 800px) {
  .Header {
    padding: 21px 32px;
    ul {
      display: none;
    }
  }
  .Logo {
    display: none;
  }
  .MobileLogo {
    display: block;
  }

  .BurgerIcon {
    display: block;
    cursor: pointer;
  }
}
