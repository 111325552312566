@import "../../../../styles/variables";

.OTPInput {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hiddenTextInput {
  width: "300px";
  border-width: 1px;
  border-radius: 5px;
  padding: 15px;
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.splitBoxes {
  display: flex;
  flex-direction: row;
  border: 0.5px solid $primary;
  position: relative;
  width: 50px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  cursor: text;
  p {
    text-align: center;
    color: $black1;
  }
}
.splitOTPBoxesContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
