@import "../../../styles/variables";

.ManageProfile {
    padding: 24px 30px;
    background-color: #fff;
    overflow: auto;
}

.ProfilesWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    padding: 0px 20px;
    justify-content: space-evenly;
    margin-top: 45px;
}

.Container {
    margin-top: 40px;
}

.Wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.Logout {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px;
    img {
      margin-bottom: 42px;
    }
  }
  
  .LogoutButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 14px;
  }
  
  #TextButton {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $grey4;
  }

  .ManageProfileModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 350px;
    padding: 20px;
  }
  .ProfileLimitModal {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    padding: 20px;
  }

  .ProfilesContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .ProfilesAvatarsList {
    margin-top: 36px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
  }

  .ManageProfileModalItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
  }

  .ManageProfileItemAvatarWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .EmptyUnpaidProfilesMessage {
    display: block;
    margin: auto;
    text-align: center;
  }