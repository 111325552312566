@import "../../../../styles/variables";

.GuardianHeader {
    width: 100%;
    padding: 20px 70px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
    @include breakpoint(sm) {
        padding: 20px;
    }
}

.UserWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 11px;
}

.User {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
        height: 40px;
        width: 40px ;
        border: 1px solid $primary;
        border-radius: 50%;
        margin-right: 11px;
        object-fit: cover;
    }
}

.ProfilesAvatarsList {
    margin-top: 36px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
  }

  .ManageProfileModalItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
  }

  .ManageProfileItemAvatarWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ManageProfileModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 300px;
    padding: 20px;
  }